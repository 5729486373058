import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbProps,
  Spinner,
} from "@chakra-ui/react";
import { Params, useMatches } from "react-router-dom";
import { LPLink } from "./LPLink";
import { ChevronRightIcon } from "@chakra-ui/icons";
import routes from "../routes";
import { useGetRoom } from "../data/room";
import { useGetTheater } from "../data/theater";
import { useGetMovieWithSessions } from "../data/movie";

type SimpleBreadcrumbProps = {
  content: string;
  to: string;
};
export const SimpleBreadcrumb: React.FC<SimpleBreadcrumbProps> = ({
  content,
  to,
}) => {
  return (
    <BreadcrumbLink as={LPLink} to={to}>
      {content}
    </BreadcrumbLink>
  );
};

type SpecificBreadcrumbProps = {
  id: string;
};
export const MovieBreadcrumb: React.FC<SpecificBreadcrumbProps> = ({ id }) => {
  const { isLoading, data: movie } = useGetMovieWithSessions(id);
  if (isLoading) {
    return <Spinner size={"xs"} />;
  }
  if (!movie) {
    return <div>Film inconnu</div>;
  }
  return (
    <BreadcrumbLink as={LPLink} to={routes.movie.render(movie.id)}>
      {movie.title}
    </BreadcrumbLink>
  );
};

export const TheaterBreadcrumb: React.FC<SpecificBreadcrumbProps> = ({
  id,
}) => {
  const { isLoading, data: theater } = useGetTheater(id);
  if (isLoading) {
    return <Spinner size={"xs"} />;
  }
  if (!theater) {
    return <div>Cinéma inconnu</div>;
  }
  return (
    <BreadcrumbLink as={LPLink} to={routes.theater.render(theater.id)}>
      {theater.name}
    </BreadcrumbLink>
  );
};

export const RoomBreadcrumb: React.FC<SpecificBreadcrumbProps> = ({ id }) => {
  const { isLoading, data: room } = useGetRoom(id);
  if (isLoading) {
    return <Spinner size={"xs"} />;
  }
  if (!room) {
    return <div>Salle inconnue</div>;
  }
  return (
    <BreadcrumbLink as={LPLink} to={routes.room.render(room.id)}>
      Salle {room.room_internal_id}
    </BreadcrumbLink>
  );
};

type Matches = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: {
    getCrumb: (data: any) => React.ReactNode;
  };
}[];

export const Breadcrumbs: React.FC<BreadcrumbProps> = (props) => {
  let matches = useMatches() as Matches;
  let crumbs = matches
    .map((match) =>
      match.handle?.getCrumb ? match.handle.getCrumb(match.params) : null,
    )
    .filter((match) => !!match);
  if (crumbs.length === 0) {
    return null;
  }

  return (
    <Breadcrumb
      spacing="8px"
      separator={<ChevronRightIcon color="gray.500" />}
      {...props}
    >
      {crumbs.map((crumb, index) => {
        return (
          <BreadcrumbItem
            key={index}
            isCurrentPage={index + 1 === crumbs.length}
            fontSize={"sm"}
            color={"gray.800"}
          >
            {crumb}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};
