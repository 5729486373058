import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { EnrichedRoom } from "../pages/types";
import { APIQueryKey } from "./utils";
import { getLoremImageUrl } from "../sample_image";

const same_floor_toilet = {
  url: "http://localhost:8000/api/toilets/1/",
  id: 1,
  name: "Toilette A",
  video_url: "https://www.youtube.com/embed/QhBnZ6NPOY0",
  description: "Toilette accessible aux personnes à mobilité réduite",
  entry_steps_count: 0,
  entry_door_width_cm: 120,
  wheelchair_toilets_count: 1,
  wheelchair_toilets_door_width_cm: 120,
  wheelchair_toilets_height_cm: 60,
  other_toilets_count: 4,
  other_toilets_door_width_cm: 120,
  other_toilets_height_cm: 40,
  washbasins_count: 4,
  washbasin_height_cm: 100,
  created_date: "2024-07-30T00:09:16.138337Z",
  modified_date: "2024-07-30T00:10:58.365875Z",
  theater: "http://localhost:8000/api/theaters/7/",
};

const a_picture = {
  // # A PICTURE_ELEM
  id: 1,
  photo: "http://localhost:8000/media/photos/rooms/test.jpg",
  url: getLoremImageUrl(300, 300),
  photo_ext_url: "http://localhost:8000/media/photos/rooms/test.jpg",
  legend: null,
  // # Reminiscent of construction
  category: "to_room",
  created_date: "2024-07-27T21:57:05.995883Z",
  modified_date: "2024-07-27T21:57:05.995893Z",
  room: "http://localhost:8000/api/rooms/2/",
};
const pictures = [
  a_picture,
  { ...a_picture, url: getLoremImageUrl(300, 301) },
  { ...a_picture, url: getLoremImageUrl(301, 300) },
  { ...a_picture, url: getLoremImageUrl(301, 301) },
];
const room = {
  url: "http://localhost:8000/api/rooms/2/",
  id: 2,
  name: "Salle 1",
  room_internal_id: "1",
  seats: 298,
  theater: {
    url: "http://localhost:8000/api/theaters/7/",
    id: 7,
    address: "6 Rue Belgrand",
    full_name: "MK2 Gambetta",
    internal_id: "0006",
    name: "Gambetta",
    complex_slug: "mk2-gambetta",
    cinema_national_id: null,
    address_1: "6 Rue Belgrand",
    address_2:
      "6, Rue Belgrand, Avallon, Yonne, Bourgogne-Franche-Comté, France métropolitaine, 89200, France",
    city: "PARIS",
    zipcode: 89200,
    latitude: 48.8646,
    longitude: 2.3995,
    parking_info:
      "Les Halles de Dumbéa, parking du lycée Dick Ukeiwé, parking de la médiathèque",
    is_gift_store: false,
    public_transport:
      "Ligne Métro : 6, 14\r\nStation Métro : Bibliothèque, Quai de la Gare\r\nLigne Bus : 27, 62, 64, 89\r\nLigne RER : CLigne Métro : 3, 3bis\r\nStation Métro : Gambetta\r\nLigne Bus : 26, 60, 61, 64, 69",
    currency_code: "EUR",
    allow_print_at_home_bookings: false,
    allow_on_line_voucher_validation: true,
    display_sofa_seats: false,
    time_zone_id: "Romance Standard Time",
    created_date: "2024-07-29T23:58:19.721512Z",
    modified_date: "2024-07-29T23:58:19.721518Z",
    brand: "http://localhost:8000/api/brands/1/",
  },
  other_pictures: [],
  // # General access desc
  accessibility_open_description: "\n",
  description_elements: [],
  theater_accessibility_open_description: "\n",
  theater_description_elements: [],
  // # Other not needed for you
  free_description: null,
  in_room_toilet_name: "Toilette A",
  same_floor_toilet_name: "Toilette B",
  other_toilets_name: "Toilette A,Toilette B",
  created_date: "2024-07-30T00:09:16.421540Z",
  modified_date: "2024-07-30T00:11:10.109508Z",
  // # The steps !
  step_inside_room: {
    pictures: pictures,
    inside_video_url: "https://www.youtube.com/embed/QhBnZ6NPOY0",
    inside_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    inside_3d_model: null,
    inside_access_point: null,
    inside_wheelchair_spaces_count: null,
    inside_seats_withnosteps_count: null,
    inside_floor: null,
    inside_mutizone_wheelchair: null,
    inside_mix_wheelchair: null,
    inside_steps_count: null,
    inside_steps_height_cm: null,
    inside_steps_with_wall: null,
    inside_seats_height_cm: null,
    inside_seats_width_cm: null,
    inside_seats_double_width_cm: null,
    inside_seats_depth_cm: null,
    inside_seats_leg_space_cm: null,
    inside_screen_glow: null,
    inside_distance_screen_min: null,
    inside_distance_screen_max: null,
    inside_decibels_min: null,
    inside_decibels_max: null,
  },
  step_corridor: {
    pictures: pictures,
    to_room_video_url: "https://www.youtube.com/embed/QhBnZ6NPOY0",
    to_room_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    to_room_floor: null,
    to_room_lift: null,
    to_room_lift_door_width_cm: null,
    to_room_lift_depth_cm: null,
    to_room_escalator: null,
    to_room_stairs: null,
    to_room_stairs_height_cm: null,
    to_room_stairs_count: null,
    to_room_full_walk: null,
    to_room_false_flat: null,
    to_room_door_width_cm: null,
    to_room_door_weight: null,
    to_room_corridor_degree_of_slope: null,
  },
  step_exit: {
    pictures: pictures,
    exit_video_url: "https://www.youtube.com/embed/QhBnZ6NPOY0",
    exit_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    exit_lift: null,
    exit_lift_door_width_cm: null,
    exit_lift_depth_cm: null,
    exit_escalator: null,
    exit_stairs: null,
    exit_stairs_height_cm: null,
    exit_stairs_count: null,
    exit_full_walk: null,
    exit_false_flat: null,
  },
  step_reception: {
    pictures: pictures,
    video_url: "https://www.youtube.com/embed/QhBnZ6NPOY0",
    free_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    hall_toilet_name: "Toilette A",
    other_toilets_name: "Toilette B",
    entry_description: null,
    entry_door_width_cm: null,
    entry_slope: null,
    popcorn_description: null,
    popcorn_height_cm: null,
    ticket_description: null,
    ticket_height_cm: null,
    ticket_digital_description: null,
    ticket_digital_height_cm: null,
    // # reminiscent of the construction
    created_date: "2024-07-30T00:09:15.919340Z",
    modified_date: "2024-07-30T00:11:10.442508Z",
    theater: "http://localhost:8000/api/theaters/7/",
    url: "http://localhost:8000/api/theatersdesc/1/",
    id: 1,
  },
  step_toilets: {
    in_room_toilet: {
      // # A TOILET_ELEM
      url: "http://localhost:8000/api/toilets/1/",
      id: 1,
      name: "Toilette A",
      video_url: "https://www.youtube.com/embed/QhBnZ6NPOY0",
      description: null,
      entry_steps_count: null,
      entry_door_width_cm: null,
      wheelchair_toilets_count: null,
      wheelchair_toilets_door_width_cm: null,
      wheelchair_toilets_height_cm: null,
      other_toilets_count: null,
      other_toilets_door_width_cm: null,
      other_toilets_height_cm: null,
      washbasins_count: null,
      washbasin_height_cm: null,
      created_date: "2024-07-30T00:09:16.138337Z",
      modified_date: "2024-07-30T00:10:58.365875Z",
      theater: "http://localhost:8000/api/theaters/7/",
    },
    same_floor_toilet: same_floor_toilet,
    hall_toilet: same_floor_toilet,
    room_other_toilets: [
      // TOILET_ELEM,
    ],
    theater_other_toilets: [
      // TOILET_ELEM,
    ],
  },
} as EnrichedRoom;

const getRoomKey = (id: string): APIQueryKey => ["get_room_details", id];
// const fetchTheaters = async (): Promise<Theater[]> => theaters;
const fetchRoom = (id: string): Promise<EnrichedRoom> => {
  //   return Promise.resolve(room);
  return fetch(`/api/rooms/${id}/access`).then((res) => {
    return res.json();
  });
};
export const useGetRoom = (id: string): UseQueryResult<EnrichedRoom> => {
  return useQuery({ queryKey: getRoomKey(id), queryFn: () => fetchRoom(id) });
};
