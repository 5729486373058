import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";

import { Box, Flex, FlexProps, Image } from "@chakra-ui/react";
import Slider from "react-slick";
import { getLoremImageUrl } from "../sample_image";
import { Picture } from "../pages/types";

const settings = {
  dots: true,
  arrows: true,
  fade: true,
  //   infinite: true,
  autoplay: true,
  // speed: 500,
  //   autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};
type CarouselProps = FlexProps & {
  pictures: Picture[];
};
export const Carousel: React.FC<CarouselProps> = ({ pictures, ...props }) => {
  console.log("Carousel: pictures", pictures);
  return (
    <Box {...props}>
      <Slider {...settings}>
        {pictures.map((picture, index) => (
          <Flex
            key={index}
            justifyContent="center"
            width={"100%"}
            display={"flex !important"}
            my={7}
          >
            <Image height={"md"} src={picture.url} alt={picture.legend || ""} />
          </Flex>
        ))}
      </Slider>
    </Box>
  );
};
