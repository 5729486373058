import {
  Center,
  Flex,
  Card,
  Text,
  Box,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import { useGetRoom } from "../data/room";
import { Carousel } from "../components/Carousel";
import { TheaterCard } from "../components/Cards";
import { useParams } from "react-router-dom";

export const RoomPage = () => {
  let { roomId = "missing id" } = useParams<{ roomId: string }>();
  const { isLoading, data: room } = useGetRoom(roomId);
  if (isLoading) {
    return <Spinner />;
  }
  if (!room) {
    return <div>Erreur, salle non trouvée</div>;
  }
  if (!room.theater) {
    return <div>Erreur, salle non rattachée à un cinéma</div>;
  }
  const sideBarWidthPercent = 40;

  const insideDetails = [];
  insideDetails.push({ name: "Nombre de places", value: room.seats, unit: "" });
  insideDetails.push({
    name: "Étage",
    value: room.step_inside_room.inside_floor,
    unit: "",
  });
  insideDetails.push({
    name: "Nombre d'espaces fauteuil",
    value: room.step_inside_room.inside_wheelchair_spaces_count,
    unit: "",
  });
  insideDetails.push({
    name: "Nombre de places de plein pied",
    value: room.step_inside_room.inside_seats_withnosteps_count,
    unit: "",
  });
  insideDetails.push({
    name: "Luminosité de l'écran",
    value: room.step_inside_room.inside_screen_glow,
    unit: "?",
  });
  insideDetails.push({
    name: "Distance 1er rang/écran",
    value: room.step_inside_room.inside_distance_screen_min,
    unit: "cm",
  });
  insideDetails.push({
    name: "Distance dernier rang/écran",
    value: room.step_inside_room.inside_distance_screen_max,
    unit: "cm",
  });
  insideDetails.push({
    name: "Nombre de places de plein pied",
    value: room.step_inside_room.inside_seats_withnosteps_count,
    unit: "",
  });
  insideDetails.push({
    name: "Dimensions de l'écran ???",
    value: null,
    unit: "",
  });
  insideDetails.push({
    name: "Hauteur d'assise",
    value: room.step_inside_room.inside_seats_height_cm,
    unit: "cm",
  });
  insideDetails.push({
    name: "Largeur d'assise",
    value: room.step_inside_room.inside_seats_width_cm,
    unit: "cm",
  });
  insideDetails.push({
    name: "Profondeur d'assise",
    value: room.step_inside_room.inside_seats_depth_cm,
    unit: "cm",
  });
  insideDetails.push({
    name: "Espace pour les jambes",
    value: room.step_inside_room.inside_seats_leg_space_cm,
    unit: "cm",
  });
  insideDetails.push({
    name: "Taille de la porte d'entrée",
    value: room.step_corridor.to_room_door_width_cm,
    unit: "cm",
  });
  insideDetails.push({
    name: "Nombre de marches dans la salle",
    value: room.step_inside_room.inside_steps_count,
    unit: "",
  });
  insideDetails.push({
    name: "Hauteur des marches dans la salle",
    value: room.step_inside_room.inside_steps_height_cm,
    unit: "",
  });
  insideDetails.push({
    name: "Nombre de marches dans la salle",
    value: room.step_inside_room.inside_steps_count,
    unit: "",
  });

  const receptionDetails = [];
  receptionDetails.push({
    name: "Largeur de la porte d'entrée",
    value: room.step_reception.entry_door_width_cm,
    unit: "cm",
  });
  receptionDetails.push({
    name: "Rampe d'accès",
    value: room.step_reception.entry_slope,
    unit: "",
  });
  receptionDetails.push({
    name: "Hauteur du comptoir de vente de popcorn",
    value: room.step_reception.popcorn_height_cm,
    unit: "cm",
  });
  receptionDetails.push({
    name: "Hauteur du comptoir de vente de billets",
    value: room.step_reception.ticket_height_cm,
    unit: "cm",
  });
  receptionDetails.push({
    name: "Hauteur des bornes automatiques de vente de billets",
    value: room.step_reception.ticket_digital_height_cm,
    unit: "cm",
  });
  const corridorDetails = [];
  corridorDetails.push({
    name: "Étage de la salle",
    value: room.step_corridor.to_room_floor,
    unit: "",
  });
  corridorDetails.push({
    name: "Présence d'un ascenseur",
    value: room.step_corridor.to_room_lift,
    unit: "",
  });
  corridorDetails.push({
    name: "Largeur de la porte d'ascenseur",
    value: room.step_corridor.to_room_lift_door_width_cm,
    unit: "cm",
  });
  corridorDetails.push({
    name: "Profondeur de la ascenseur",
    value: room.step_corridor.to_room_lift_depth_cm,
    unit: "cm",
  });
  corridorDetails.push({
    name: "Présence d'un escalator",
    value: room.step_corridor.to_room_escalator,
    unit: "",
  });
  corridorDetails.push({
    name: "Faux plat",
    value: room.step_corridor.to_room_false_flat,
    unit: "",
  });
  corridorDetails.push({
    name: "Pente des couloirs",
    value: room.step_corridor.to_room_corridor_degree_of_slope,
    unit: "degrès",
  });
  const exitDetails = [];
  exitDetails.push({
    name: "Ascenseur",
    value: room.step_exit.exit_lift,
    unit: "",
  });
  exitDetails.push({
    name: "Largeur de la porte d'ascenseur",
    value: room.step_exit.exit_lift_door_width_cm,
    unit: "cm",
  });
  exitDetails.push({
    name: "Profondeur de la porte d'ascenseur",
    value: room.step_exit.exit_lift_depth_cm,
    unit: "cm",
  });
  exitDetails.push({
    name: "Escalator",
    value: room.step_exit.exit_escalator,
    unit: "",
  });
  exitDetails.push({
    name: "Faux plat",
    value: room.step_exit.exit_false_flat,
    unit: "",
  });
  return (
    <>
      <TheaterCard
        name={room.theater.full_name + " - " + room.name}
        address={room.theater.address}
        mb={4}
      />
      <Flex flexDirection="column" w="100%" mt={4}>
        <Tabs isFitted>
          <TabList>
            <Tab>Salle</Tab>
            <Tab>Accueil</Tab>
            <Tab>Couloirs</Tab>
            <Tab>Toilettes</Tab>
            <Tab>Sortie</Tab>
          </TabList>

          <TabPanels>
            {/* Salle */}
            <TabPanel>
              <Flex flexDirection={{ base: "column", md: "row" }} gap={3}>
                <Box
                  w={{ base: "100%", md: `${sideBarWidthPercent}%` }}
                  pr={{ base: "0", md: 3 }}
                  borderRight={{ base: "none", md: "1px solid gray" }}
                >
                  <UnorderedList fontSize="sm">
                    {insideDetails.map((detail) => (
                      <ListItem>
                        {detail.name}:{" "}
                        {detail.value === null ? "N/A" : detail.value}
                        {detail.value && detail.unit}
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
                <Flex
                  w={{
                    base: "100%",
                    md: `${100 - sideBarWidthPercent}%`,
                  }}
                  flexDirection={"column"}
                >
                  <Flex justifyContent={"center"}>
                    <Box>
                      <iframe
                        title="naruto"
                        src={room.step_inside_room.inside_video_url || ""}
                        allowFullScreen
                      />
                    </Box>
                  </Flex>

                  {room.step_inside_room.inside_description && (
                    <Card mt={3} p={3} bg="#D8D9DA">
                      <Text>{room.step_inside_room.inside_description}</Text>
                    </Card>
                  )}
                  {room.step_inside_room.pictures && (
                    <Center>
                      <Carousel
                        my={7}
                        mx={3}
                        w="90%"
                        pictures={room.step_inside_room.pictures}
                      />
                    </Center>
                  )}
                </Flex>
              </Flex>
            </TabPanel>
            {/* Accueil */}
            <TabPanel>
              <Flex flexDirection={{ base: "column", md: "row" }} gap={3}>
                <Box
                  w={{ base: "100%", md: `${sideBarWidthPercent}%` }}
                  pr={{ base: "0", md: 3 }}
                  borderRight={{ base: "none", md: "1px solid gray" }}
                >
                  <UnorderedList fontSize="sm">
                    {receptionDetails.map((detail) => (
                      <ListItem>
                        {detail.name}:{" "}
                        {detail.value === null ? "N/A" : detail.value}
                        {detail.value && detail.unit}
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
                <Flex
                  w={{
                    base: "100%",
                    md: `${100 - sideBarWidthPercent}%`,
                  }}
                  flexDirection={"column"}
                >
                  {room.step_reception.video_url && (
                    <Flex justifyContent={"center"}>
                      <Box>
                        <iframe
                          title="naruto"
                          src={room.step_reception.video_url || ""}
                          allowFullScreen
                        />
                      </Box>
                    </Flex>
                  )}
                  {room.step_reception.entry_description && (
                    <Card mt={3} p={3} bg="#D8D9DA">
                      <Text>{room.step_reception.entry_description}</Text>
                    </Card>
                  )}
                  {room.step_reception.pictures && (
                    <Center>
                      <Carousel
                        my={7}
                        mx={3}
                        w="90%"
                        pictures={room.step_reception.pictures}
                      />
                    </Center>
                  )}
                </Flex>
              </Flex>
            </TabPanel>

            {/* Couloirs */}
            <TabPanel>
              <Flex flexDirection={{ base: "column", md: "row" }} gap={3}>
                <Box
                  w={{ base: "100%", md: `${sideBarWidthPercent}%` }}
                  pr={{ base: "0", md: 3 }}
                  borderRight={{ base: "none", md: "1px solid gray" }}
                >
                  <UnorderedList fontSize="sm">
                    {corridorDetails.map((detail) => (
                      <ListItem>
                        {detail.name}:{" "}
                        {detail.value === null ? "N/A" : detail.value}
                        {detail.value && detail.unit}
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
                <Flex
                  w={{
                    base: "100%",
                    md: `${100 - sideBarWidthPercent}%`,
                  }}
                  flexDirection={"column"}
                >
                  {room.step_corridor.to_room_video_url && (
                    <Flex justifyContent={"center"}>
                      <Box>
                        <iframe
                          title="naruto"
                          src={room.step_corridor.to_room_video_url || ""}
                          allowFullScreen
                        />
                      </Box>
                    </Flex>
                  )}
                  {room.step_corridor.to_room_description && (
                    <Card mt={3} p={3} bg="#D8D9DA">
                      <Text>{room.step_corridor.to_room_description}</Text>
                    </Card>
                  )}
                  {room.step_corridor.pictures && (
                    <Center>
                      <Carousel
                        my={7}
                        mx={3}
                        w="90%"
                        pictures={room.step_corridor.pictures}
                      />
                    </Center>
                  )}
                </Flex>
              </Flex>
            </TabPanel>

            {/* Toilettes */}
            <TabPanel>
              <Flex flexDirection={"column"}>
                {[
                  room.step_toilets.in_room_toilet,
                  room.step_toilets.same_floor_toilet,
                ]
                  .filter((x) => !!x)
                  .map((toilet) => (
                    <Card mt={3} p={3} bg="#D8D9DA">
                      <Text>{toilet?.name}</Text>
                      <Text mt={3}>{toilet?.description}</Text>
                      <Flex
                        flexDirection={{ base: "column", md: "row" }}
                        gap={3}
                      >
                        <Box
                          w={{ base: "100%", md: `${sideBarWidthPercent}%` }}
                          pr={{ base: "0", md: 3 }}
                          borderRight={{ base: "none", md: "1px solid gray" }}
                        >
                          <UnorderedList fontSize={"sm"}>
                            <ListItem>
                              Nombre de marches:{" "}
                              {toilet?.entry_steps_count
                                ? toilet.entry_steps_count
                                : "N/A"}
                            </ListItem>
                            <ListItem>
                              Largeur porte d'entrée:{" "}
                              {toilet?.entry_door_width_cm
                                ? toilet.entry_door_width_cm + "cm"
                                : "N/A"}
                            </ListItem>
                            <ListItem>
                              Nombre de toilettes fauteuil:{" "}
                              {toilet?.wheelchair_toilets_count
                                ? toilet.wheelchair_toilets_count
                                : "N/A"}
                            </ListItem>
                            <ListItem>
                              Hauteur toilettes fauteuil:{" "}
                              {toilet?.wheelchair_toilets_count
                                ? toilet.wheelchair_toilets_count + "cm"
                                : "N/A"}
                            </ListItem>
                            <ListItem>
                              Largeur porte des toilettes fauteuil:{" "}
                              {toilet?.wheelchair_toilets_door_width_cm
                                ? toilet.wheelchair_toilets_door_width_cm + "cm"
                                : "N/A"}
                            </ListItem>
                            <ListItem>
                              Nombre des toilettes non fauteuil:{" "}
                              {toilet?.other_toilets_count
                                ? toilet.other_toilets_count
                                : "N/A"}
                            </ListItem>
                            <ListItem>
                              Hauteur toilettes non fauteuil:{" "}
                              {toilet?.other_toilets_height_cm
                                ? toilet.other_toilets_height_cm + "cm"
                                : "N/A"}
                            </ListItem>
                            <ListItem>
                              Largeur porte des toilettes non fauteuil:{" "}
                              {toilet?.other_toilets_door_width_cm
                                ? toilet.other_toilets_door_width_cm + "cm"
                                : "N/A"}
                            </ListItem>
                            <ListItem>
                              Nombre de lavabos:{" "}
                              {toilet?.washbasins_count
                                ? toilet.washbasins_count
                                : "N/A"}
                            </ListItem>
                            <ListItem>
                              Hauteur des lavabos:{" "}
                              {toilet?.washbasin_height_cm
                                ? toilet.washbasin_height_cm + "cm"
                                : "N/A"}
                            </ListItem>
                          </UnorderedList>
                        </Box>
                        <Flex
                          w={{
                            base: "100%",
                            md: `${100 - sideBarWidthPercent}%`,
                          }}
                          flexDirection={"column"}
                        >
                          <Flex justifyContent={"center"}>
                            <Box>
                              {toilet?.video_url ? (
                                <iframe
                                  title="Toilettes dans la salle"
                                  src={toilet.video_url}
                                  allowFullScreen
                                />
                              ) : (
                                <Text>Video non disponible</Text>
                              )}
                            </Box>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Card>
                  ))}
              </Flex>
            </TabPanel>

            {/* Sortie */}
            <TabPanel>
              <Flex flexDirection={{ base: "column", md: "row" }} gap={3}>
                <Box
                  w={{ base: "100%", md: `${sideBarWidthPercent}%` }}
                  pr={{ base: "0", md: 3 }}
                  borderRight={{ base: "none", md: "1px solid gray" }}
                >
                  <UnorderedList fontSize="sm">
                    {exitDetails.map((detail) => (
                      <ListItem>
                        {detail.name}:{" "}
                        {detail.value === null ? "N/A" : detail.value}
                        {detail.value && detail.unit}
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
                <Flex
                  w={{
                    base: "100%",
                    md: `${100 - sideBarWidthPercent}%`,
                  }}
                  flexDirection={"column"}
                >
                  {room.step_exit.exit_video_url && (
                    <Flex justifyContent={"center"}>
                      <Box>
                        <iframe
                          title="naruto"
                          src={room.step_exit.exit_video_url || ""}
                          allowFullScreen
                        />
                      </Box>
                    </Flex>
                  )}
                  {room.step_exit.exit_description && (
                    <Card mt={3} p={3} bg="#D8D9DA">
                      <Text>{room.step_exit.exit_description}</Text>
                    </Card>
                  )}
                </Flex>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  );
};
